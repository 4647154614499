import React from "react";

class Loadingcfg extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="row" id="loading"
                style={{ "position": "absolute", "marginTop": "20px", "left": "50%", "transform": "translate(-50%, -50%)" }}>
                <div className="col-sm-12 text-center">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            </div>
        );
    }
}

export default Loadingcfg;
