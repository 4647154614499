import React from "react";
import { withTranslation } from "react-i18next";
import logoPlayOn from "./../../assets/img/Play-On.png";

const logoIconSave = process.env.REACT_APP_BASE_URL + "/assets/img/icon-save-tab-static.png";

class Play extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playOnShow: true,
      playOffShow: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col-sm-12" id="div-play">
          <div className="mt-3 text-center">
            {this.props.tabStatic ? (
              <a
                onClick={this.props.saveSettings}
                className="a-hover"
                id="play-on"
              >
                {" "}
                <img
                  src={logoIconSave}
                  className="btn-action"
                  alt={t("readyToSave")}
                />{" "}
              </a>
            ) : (
              <a onClick={this.props.playBot} className="a-hover" id="play-on">
                {" "}
                <img
                  src={logoPlayOn}
                  className="btn-action"
                  alt={t("readyToStart")}
                />{" "}
              </a>
            )}
          </div>
          <div>
            <p className="mt-1 pb-mb-0 text-center">
              {this.props.tabStatic ? (
                <label className="label-white" style={{ fontSize: "14px" }}>
                  {" "}
                  {t("readyToSave")}{" "}
                </label>
              ) : (
                <label className="label-white" style={{ fontSize: "14px" }}>
                  {" "}
                  {t("readyToStart")}{" "}
                </label>
              )}
            </p>
            {this.props.tabStatic ? (
              <p className="text-center" style={{ fontSize: "12px" }}>
                {" "}
                {t("messageReadyToSave")}{" "}
              </p>
            ) : (
              <p className="text-center" style={{ fontSize: "12px" }}>
                {" "}
                {t("messageReadyToStart")}{" "}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Play);
