import React from "react";
import { withTranslation } from "react-i18next";
import logoPauseOn from './../../assets/img/Pause-On.png';

class Pause extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className="row">
                <div className="col-sm-12" id="div-pause">
                    <div className="mt-3 text-center">
                        <a onClick={this.props.pauseBot} className="a-hover" id="pause-on"> <img src={logoPauseOn} className="btn-action" alt={t('iAmActive')} /> </a>
                    </div>
                    <div>
                        <p className="mt-1 pb-mb-0 text-center">
                            <label className="label-white" style={{ "fontSize": "14px" }}> {t('iAmActive')} </label>
                        </p>
                        <p className="text-center" style={{ "fontSize": "12px" }}> {t('messageIAmActive')} </p>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Pause);
