import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_ES } from "./es/translations";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en-US': {
                translation: TRANSLATIONS_EN
            },
            'pt-BR': {
                translation: TRANSLATIONS_PT
            },
            'es-ES': {
                translation: TRANSLATIONS_ES
            },
        }
    });

var userLang = navigator.language || navigator.userLanguage;
i18n.changeLanguage(userLang);

console.log(userLang);
