import React from "react";
import { withTranslation } from "react-i18next";
import logoInactive from "./../../assets/img/Inactive.png";

class Preplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t } = this.props;
        return (
            <div className="row">
                <div className="col-sm-12" id="div-pre-play">
                    <div className="mt-3 text-center">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            {" "}
                            <img
                                src={logoInactive}
                                className="btn-action"
                                alt={t("inactive")}
                            />{" "}
                        </a>
                    </div>
                    <div>
                        <p className="mt-1 pb-mb-0 text-center">
                            <label className="label-white" style={{ fontSize: "14px" }}>
                                {" "}
                                {t("inactive")}{" "}
                            </label>
                        </p>
                        {this.props.tabStatic ? (
                            <p className="text-center" style={{ fontSize: "12px" }}>
                                {" "}
                                {t("messageInactiveTabStatic")}{" "}
                            </p>
                        ) : (
                            <p className="text-center" style={{ fontSize: "12px" }}>
                                {" "}
                                {t("messageInactive")}{" "}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Preplay);
