import React from "react";
import { withTranslation } from "react-i18next";

import Api from '../../Api';
import { app } from "@microsoft/teams-js";
import { infoLog, errorLog, } from '../telemetry/LogServiceInterface';

const $ = window.$;

class Feedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stFormEmpty: true,
            question1: { stars: 0 },
            question2: { stars: 0 },
            question3: '',
            saveBtnShow: false,
            context: {
                locale: '',
                theme: '',
                userObjectId: '',
                userPrincipalName: '',
                tid: '',
                meetingId: '',
                chatId: '',
            }
        };
    }

    componentDidMount() {
        var Context = {};

        app.getContext().then((_context) => {
            Context.locale = _context.app.locale;
            Context.theme = _context.app.theme;
            Context.userObjectId = _context.user.id;
            Context.userPrincipalName = _context.user.userPrincipalName;
            Context.tid = _context.user.tenant.id;
            Context.meetingId = _context.meeting.id;
            Context.chatId = _context.chat.id;

            this.setState({ context: Context }, this.getUserInfo);
        }).catch((error) => {
            errorLog('get context in feedback error: ' + JSON.stringify(error));
            console.error(error)
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="div-center" id="page-feedback">
                <h4 className="text-center">
                    {t('voiceroFeedback')}
                </h4>
                <form className="mt-3">
                    <div className="form-group">
                        <p className="stars-area">
                            <span id="qt_1">
                                {t('question1')}
                            </span> <br />
                            <span className="fa fa-star" id="star_1_1" onClick={() => { this.setStarRating(1, 1); }}></span>
                            <span className="fa fa-star" id="star_1_2" onClick={() => { this.setStarRating(1, 2); }}></span>
                            <span className="fa fa-star" id="star_1_3" onClick={() => { this.setStarRating(1, 3); }}></span>
                            <span className="fa fa-star" id="star_1_4" onClick={() => { this.setStarRating(1, 4); }}></span>
                            <span className="fa fa-star" id="star_1_5" onClick={() => { this.setStarRating(1, 5); }}></span>
                        </p>
                    </div>
                    <div className="form-group">
                        <p className="stars-area">
                            <span id="qt_2">
                                {t('question2')}
                            </span> <br />
                            <span className="fa fa-star" id="star_2_1" onClick={() => { this.setStarRating(2, 1); }}></span>
                            <span className="fa fa-star" id="star_2_2" onClick={() => { this.setStarRating(2, 2); }}></span>
                            <span className="fa fa-star" id="star_2_3" onClick={() => { this.setStarRating(2, 3); }}></span>
                            <span className="fa fa-star" id="star_2_4" onClick={() => { this.setStarRating(2, 4); }}></span>
                            <span className="fa fa-star" id="star_2_5" onClick={() => { this.setStarRating(2, 5); }}></span>
                        </p>
                    </div>
                    <div className="form-group" id="textarea-comment">
                        <p className="text-center">
                            <span id="qt_3">
                                {t('question3')}
                            </span> <br />
                            <textarea className="form-control" id="textareaComment" name="textareaComment" onKeyUp={this.changeComment}></textarea>
                        </p>
                    </div>
                </form>
                <p className="mt-3 text-center" style={{ "minHeight": "31px" }}>
                    {
                        !this.state.saveBtnShow ?
                            <a id="btn-pre-save" onClick={this.sendFeedback} className="btn btn-sm btn-light">
                                <i className="fas fa-paper-plane"></i> {t('send')}
                            </a>
                            :
                            <a id="btn-save" onClick={this.sendFeedback} title="Send feedback" className="btn btn-sm btn-primary">
                                <i className="fas fa-paper-plane"></i> {t('send')}
                            </a>
                    }   
                    <span id="btn-saving"> {t('sending')}..</span>
                </p>
            </div>
        )
    }

    setStarRating = (idx, ord) => {
        this.emptyStarRating(idx);
        for (var i = 1; i <= ord; i++) {
            $("#star_" + idx + "_" + i).addClass("fa fa-star star-checked");
            if (i === ord) {
                if (idx === 1) {
                    this.setState({
                        ...this.state,
                        stFormEmpty: false,
                        question1: { stars: ord },
                        saveBtnShow: true,
                    }, () => console.log(this.state));
                } else if (idx === 2) {
                    this.setState({
                        ...this.state,
                        stFormEmpty: false,
                        question2: { stars: ord },
                        saveBtnShow: true,
                    }, () => console.log(this.state));
                }
            }
        }
    }

    emptyStarRating = (idx) => {
        for (var i = 1; i <= 5; i++) {
            $("#star_" + idx + "_" + i).even().removeClass("star-checked");
        }
    }

    changeComment = (event) => {
        if ($("#textareaComment").val() !== "") {
            this.setState({
                ...this.state,
                question3: event.target.value,
                stFormEmpty: false,
                saveBtnShow: true
            });
        } else {
            this.setState({
                ...this.state,
                question3: event.target.value,
                stFormEmpty: true,
                saveBtnShow: false,
            });
        }
    }

    sendFeedback = async () => {
        if (!this.state.stFormEmpty) {
            let questionStars = [
                { idText: 1, stars: this.state.question1.stars },
                { idText: 2, stars: this.state.question2.stars }
            ];
            let sendFeedbackRequestOptions = {
                successCallback: (result) => { this.sendFeedbackSuccess(result) },
                failureCallback: (error) => { this.sendFeedbackFailure(error) }
            };

            await Api.sendFeedback(this.state.context.userObjectId, this.state.context.userPrincipalName, this.state.context.chatId, questionStars, this.state.question3, sendFeedbackRequestOptions);
        } else {
            this.alertQuestions();
        }
    }

    sendFeedbackSuccess = async (result) => {
        infoLog('Send feedback Success');
        window.location = window.location.origin;
    }

    sendFeedbackFailure(error) {
        error('Send Feedback error');
        console.log(error);
    }

    alertQuestions = () => {
        $('#qt_1').css('color', '#dc3545');
        $('#qt_2').css('color', '#dc3545');
        $('#qt_3').css('color', '#dc3545');
        this.defaultQuestions();
    }

    defaultQuestions = () => {
        setTimeout(function () {
            $('#qt_1').css('color', '#919191');
            $('#qt_2').css('color', '#919191');
            $('#qt_3').css('color', '#919191');
        }, 1000);
    }

}

export default withTranslation()(Feedback);
