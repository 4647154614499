import React from "react";
import { app, authentication } from "@microsoft/teams-js";
import Api from '../../Api';
import { infoLog, errorLog } from '../telemetry/LogServiceInterface';
import Loading from './../widgets/Loading';
import logo from './../../assets/img/logo.png';
import { withTranslation, Trans } from "react-i18next";

class AuthEnd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            context: {},
            message: '',
            loading: true,
            access_token: ''
        };
    }
    componentDidMount() {

        app.initialize()
            .then((response) => {
                var Context = {};
                app.getContext().then((context) => {
                    Context.locale = context.app.locale;
                    Context.theme = context.app.theme;
                    Context.userObjectId = context.user.id;
                    Context.userPrincipalName = context.user.userPrincipalName;
                    Context.tid = context.user.tenant.id;
                    Context.meetingId = context.meeting.id;
                    Context.chatId = context.chat.id;

                    localStorage.setItem('userPrincipalName', context.user.userPrincipalName);

                    infoLog('Success Loading configure context');

                    this.setState({ context: Context }, () => {
                        let hashParams = this.getHashParameters();

                        if (hashParams["access_token"]) {
                            window.localStorage.setItem("access_token", hashParams["access_token"]);
                            this.setState({
                                access_token: hashParams["access_token"]
                            });
                            this.consentSuccess(hashParams["access_token"]);
                        } else {
                            window.localStorage.setItem("authentication", 'finished-error');
                            authentication.notifyFailure("Error");
                        }
                    });

                });                
            })
            .catch((error) => {
                authentication.notifyFailure("App initialize failed");
            });        
    }

    getHashParameters() {
        let hashParams = {};
        window.location.hash.substr(1).split("&").forEach(function (item) {
            let [key, value] = item.split('=');
            hashParams[key] = decodeURIComponent(value);
        });
        return hashParams;
    }    

    render() {
        const { t } = this.props;
        return (
            this.state.loading ?
                <Loading displayStyle="initial" />
                :
                <div className="div-center" id="page-confirm">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Voicero</h5>
                            <p className="card-text">
                                {this.state.message}
                            </p>
                        </div>
                        <div>
                            <img src={logo} className="logo-app" alt="Voicero" width="35" />
                        </div>
                    </div>
                </div>
        )
    }

    async consentSuccess(result) {
        infoLog('Authentication Microsoft Office Success');

        this.setState({
            graphAccessToken: result
        });
        let authorizeRequestOptions = {
            successCallback: (result) => { this.authorizeSuccess(result) },
            failureCallback: (error) => { this.authorizeFailure(error) }
        };
        await Api.authorize(this.state.context.meetingId, this.state.context.chatId, this.state.context.userObjectId, this.state.context.tid, result, this.state.context.userPrincipalName, authorizeRequestOptions)
    }

    authorizeSuccess = async (result) => {
        infoLog('Authorization Voicero Success');
        window.parent.postMessage('success');
        authentication.notifySuccess('Success');
    }

    authorizeFailure(error) {
        var msg = '';
        if (error.status == 401) {
            this.setState({
                message: this.props.t('messageInfoUnauthorized'),
                loading: false,
            });
            msg = 'Unauthorized';
        } else if (error.status == 403) {
            this.setState({
                message: (<Trans i18nKey="messageInfoForbidden">Prefix <a href="https://voicero.net/" target="_blank">link</a> suffix</Trans>),
                loading: false,
            });
            msg = 'Forbidden';
        } else if (error.status == 404) {
            this.setState({
                message: this.props.t('messageInfoError'),
                loading: false,
            });
            msg = 'NotFound';
        } else {
            this.setState({
                message: this.props.t('messageErrorAuth'),
                loading: false,
            });
            msg = 'Error';
            errorLog('Occurr error in authorize user : ' + JSON.stringify(error));
        }
        window.parent.postMessage(msg);
        setTimeout(() => {
            authentication.notifyFailure(msg);
        }, 1500);
    }
}

export default withTranslation()(AuthEnd);
