import React from 'react';
import { Routes, Route, useNavigate} from "react-router-dom";
import { app} from "@microsoft/teams-js";

import { getAppInsights } from './components/telemetry/TelemetryService';
import TelemetryProvider from './components/telemetry/TelemetryProvider';

import Home from './components/pages/Home';
import Feedback from './components/pages/Feedback';
import Configure from './components/pages/Configure';
import AuthStart from './components/pages/AuthStart';
import AuthEnd from './components/pages/AuthEnd';
import Tab from './components/pages/Tab';

import ConfigureTabStatic from './components/pages/tabStatic/ConfigureTabStatic';
import HomeTabStatic from './components/pages/tabStatic/HomeTabStatic';

function App() {
    app.initialize()
        .then((response) => {
            app.notifySuccess();
        })
        .catch((error) => {
            app.initialize();
        });

    var history = useNavigate();
    var appInsights = null;

    return (
        <TelemetryProvider history={history} instrumentationKey={process.env.REACT_APP_INSTRUMENTATION_KEY} connectionString={process.env.REACT_APP_CONNECTION_STRING} after={() => { appInsights = getAppInsights() }}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/feedback" element={<Feedback />} />
                <Route path="/configure" element={<Configure />} />
                <Route path="/auth-start" element={<AuthStart />} />
                <Route path="/auth-end" element={<AuthEnd />} />
                <Route path="/tab" element={<Tab />} />
                <Route path="/tab-static/configure-tab-static" element={<ConfigureTabStatic />} />
                <Route path="/tab-static/home-tab-static" element={<HomeTabStatic />} />
            </Routes>
        </TelemetryProvider>
    );
}

export default App;