import React from "react";
import { withTranslation } from "react-i18next";

import Api from "../../../Api";

import Preplay from "../../widgets/Preplay";
import Play from "../../widgets/Play";
import Loadingcfg from "../../widgets/Loadingcfg";
import Form from "../../widgets/Form";
import Alert from "../../widgets/Alert";

import { app } from "@microsoft/teams-js";

import { infoLog, errorLog } from "../../telemetry/LogServiceInterface";

class HomeTabStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      loading: true,
      setupShow: false,
      languages: [],
      tabStatic: true,
      formData: {
        input: "",
        output: "",
        voice: "",
      },
      formSelectDisabled: {
        input: false,
        output: false,
        voice: false,
      },
      context: {
        locale: "",
        theme: "",
        userObjectId: "",
        userPrincipalName: "",
      },
      cmpntPreSaveShow: true,
      cmpntLoadingCfgShow: false,
      cmpntVoicesCfgShow: false,
    };
  }

  async componentDidMount() {
    var Context = {};

    app
      .getContext()
      .then((_context) => {
        Context.locale = _context.app.locale;
        Context.theme = _context.app.theme;
        Context.userObjectId = _context.user.id;
        Context.userPrincipalName = _context.user.userPrincipalName;

        this.setState({ context: Context }, this.getUserConfig);
      })
      .catch((error) => {
        errorLog("get context in feedback error: " + JSON.stringify(error));
        console.error(error);
      });
  }

  render() {
    return (
      <>
        {
          <>
            {this.state.setupShow ? (
              <div className="div-center top-45" id="page-setup-temp">
                {/* Form Area */}
                {
                  <Form
                    setFormDataMethod={this.setFormData}
                    formData={this.state.formData}
                    listLanguage={this.listLanguage}
                    languages={this.state.languages}
                    formSelect={this.state.formSelectDisabled}
                  />
                }

                {/* Action Area */}
                {this.state.cmpntPreSaveShow ? (
                  <Preplay tabStatic={this.state.tabStatic} />
                ) : (
                  <Play
                    saveSettings={this.saveSettings}
                    tabStatic={this.state.tabStatic}
                  />
                )}

                {/* Loading Config Area */}
                {this.state.cmpntLoadingCfgShow ? <Loadingcfg /> : null}

                {/* Alert Area */}
                {this.state.cmpntAlertShow ? (
                  <Alert message={this.state.message} />
                ) : null}
              </div>
            ) : null}
          </>
        }
      </>
    );
  }

  getUserConfig = async () => {
    let getParticipantRequestOptions = {
      successCallback: (result) => {
        this.getUserConfigSuccess(result);
      },
      failureCallback: (error) => {
        this.getUserConfigFailure(error);
      },
    };

    await Api.getConfigUser(
      this.state.context.userObjectId,
      getParticipantRequestOptions
    );
  };

  getUserConfigSuccess = async (result) => {
    infoLog("Success Get User Config");
    this.renderForm(result);
  };

  getUserConfigFailure(error) {
    console.error(error);
    errorLog("Error Get User Config" + JSON.stringify(error));
  }

  renderForm(result) {
    if (
      result != null &&
      result.userId != null &&
      result.inputLanguage != null &&
      result.outputLanguage != null &&
      result.outputVoiceGender != null
    ) {
      infoLog("User set languages for translated" + JSON.stringify(result));
      let form = {
        input: result.inputLanguage,
        output: result.outputLanguage,
        voice: result.outputVoiceGender.toLowerCase(),
      };
      let select = {
        input: false,
        output: false,
        voice: false,
      };

      this.setState(
        {
          formData: form,
          formSelectDisabled: select,
        },
        () => {
          this.showForm();
        }
      );
    } else {
      let form = {
        input: "",
        output: "",
        voice: "",
      };
      let select = {
        input: false,
        output: false,
        voice: false,
      };

      this.setState(
        {
          formData: form,
          formSelectDisabled: select,
        },
        () => {
          this.showForm();
        }
      );
    }
  }

  showForm = (callback) => {
    this.setState(
      {
        loading: false,
        setupShow: true,
        cmpntVoicesCfgShow: true,
        cmpntPreSaveShow: true,
      },
      callback
    );
  };

  setFormData = async (data) => {
    this.setState(
      {
        ...this.state,
        formData: data,
      },
      async () => {
        if (
          this.state.formData.input !== "" &&
          this.state.formData.output !== "" &&
          this.state.formData.voice !== ""
        ) {
          this.setState({
            cmpntPreSaveShow: false,
          });
        } else {
          this.setState({
            cmpntPreSaveShow: true,
          });
        }
      }
    );
  };

  saveSettings = async () => {
    this.setStartingLoading();

    let saveSettingsOptions = {
      successCallback: (result) => {
        this.saveSettingsSuccess(result);
      },
      failureCallback: (error) => {
        this.saveSettingsFailure(error);
      },
    };

    await Api.saveSettings(
      this.state.context.userObjectId,
      this.state.formData.input,
      this.state.formData.output,
      this.state.formData.voice,
      saveSettingsOptions
    );
  };

  saveSettingsSuccess(result) {
    let select = {
      input: false,
      output: false,
      voice: false,
    };

    this.setState({
      ...this.state,
      formSelectDisabled: select,
      cmpntPreSaveShow: true,
      cmpntLoadingCfgShow: false,
      cmpntAlertShow: true,
      message: this.props.t("messageConfigSuccess"),
    });

    setTimeout(() => {
      this.setState({
        message: "",
        cmpntAlertShow: false,
      });
    }, 2000);
  }

  saveSettingsFailure(error) {
    let select = {
      input: false,
      output: false,
      voice: false,
    };

    this.setState({
      ...this.state,
      cmpntLoadingCfgShow: false,
      formSelectDisabled: select,
      message: this.props.t("messageConfigFailure"),
    });

    setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 3000);

    errorLog("Error in send settings: " + JSON.stringify(error));
    console.error(error);
  }

  setStartingLoading = async () => {
    var select = {
      input: true,
      output: true,
      voice: true,
    };
    this.setState({
      ...this.state,
      cmpntLoadingCfgShow: true,
      formSelectDisabled: select,
    });
  };

  listLanguage = async () => {
    let listLanguageRequestOptions = {
      successCallback: (result) => {
        this.listLanguageSuccess(result);
      },
      failureCallback: (error) => {
        this.listLanguageFailure(error);
      },
    };

    await Api.listLanguage("pt", listLanguageRequestOptions);
    return this.state.languages;
  };

  listLanguageSuccess = async (result) => {
    infoLog("Get Language List Success");
    if (result.length > 0) {
      this.state.languages = result;
    }
  };

  listLanguageFailure(error) {
    errorLog("Get Language List Error");
    console.error(error);
  }
}
export default withTranslation()(HomeTabStatic);
