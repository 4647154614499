export const TRANSLATIONS_EN = {
    // Home page
    'welcome': 'Welcome to Voicero',
    'cancel': 'Cancel',
    'configure': 'Configure',
    'confirm': 'Confirm',
    'error': 'Error',
    'female': 'Female',
    'help': 'Help',
    'iAmActive': 'Active',
    'inactive': 'Inactive',
    'leave': 'Leave',
    'male': 'Male',
    'messageParticipant': 'Wait for the organizer to start the translator',
    'messageIAmActive': 'Click the Icon to Pause me',
    'messageInactive': 'Please Complete Setup',
    'messageInactiveTabStatic': 'Please Complete or change your setup to save',
    'messageLeave': 'Are you sure you want to turn off the chat bot from the meeting ?',
    'messageReadyToStart': 'Click the Icon to Activate',
    'messageReadyToSave': 'Click the Icon to Save',
    'messageWelcome': 'Hello Voicero is in the meeting.I can perform translation between 18 languages in Teams meeting.Whenever you want you can mute / unmute me or ask me to leave the meeting if you don\'t need me anymore.',
    'messageSuccessAuth': 'Success Authenticate!',
    'messageUnauthorizedAuth': 'You�re not authorized. Check your subscription.',
    'messageErrorAuth': 'Something went wrong. Please, try again!',
    'mute': 'Mute',
    'play': 'Play',
    'organize': 'Organizer:',
    'readyToStart': 'Start',
    'readyToSave': 'Save',
    'reload': 'Reload',
    'save': 'Save',
    'saving': 'Saving',
    'selectInput': 'Select Input',
    'selectInputDesc': 'Native language',
    'selectOutput': 'Select Output',
    'selectOutputDesc': 'Output language',
    'selectVoice': 'Select Voice',
    'selectVoiceDesc': 'Desired voice',
    'starting': 'Starting',
    'step': 'Step',
    'stop': 'Stop',
    'titleHelp': 'Help Voicero App',
    'titleLeave': 'Leave Voicero App',
    'titleMute': 'Mute Voicero',
    'titlePause': 'Pause Translation',
    'titlePlay': 'Play Translation',
    'titleSave': 'Save Settings',
    'titleUnmute': 'Unmute Voicero',
    'unmute': 'Talk',
    'voiceroSettings': 'Voicero Settings',
    'voiceroListining': 'Voicero is listining',
    'voiceroTranslating': 'Voicero is translating',
    'participants': 'participants',
    'you': 'you',
    // Feedback page
    'messageSendError': 'Feedback not sent, please wait and try again.',
    'messageSendSuccess': 'Feedback sent successfully.',
    'question1': 'About the quality of the translation?',
    'question2': 'Do you recommend Voicero?',
    'question3': 'Leave us your opinion.',
    'send': 'Send',
    'sending': 'Sending',
    'success': 'Success',
    'titleSend': 'Send feedback',
    'voiceroFeedback': 'Voicero Feedback',
    // Configure page
    'messageButton': 'Login',
    'messageConfirm': 'Starting authentication',
    'messageConfigSuccess': 'Configuration saved successfully',
    'messageConfigFailure': 'Configuration not sent, please wait and try again.',
    'messageInfoNotAuth': 'Perform an authentication to insert the bot in the call',
    'messageInfoError': 'You are not authorized to enter the Voicero app, please contact the meeting organizer.',
    'messageInfoSuccess': 'You are authorized to use Voicero App. We are starting the translator!',
    'messageInfoUnauthorized': 'Voicero is already on this call!',
    'messageInfoForbidden': `Sorry, we couldn't find your Voicero subscription. Check if your e-mail is validated or check out our subscription options on our <1>page</1>.Thanks!`,
    //tab page
    'textTitile': 'Unmute your potential',
    'textFirstParagrafh': 'Voicero translates voice in real-time, making it easy to express your best ideas during Microsoft Teams meetings.',
    'textSecondParagrafh': 'Enter and configure Voicero with the meeting in progress.',
    'textThirthParagrafh': 'You will need to click on the tab to configure',
    'messageUsingVoicero': 'Using Voicero',
    'messageTutorials': 'Tutorials',
    'messageButton2': 'View Site'
};
