import { getAppInsights } from './TelemetryService';
import { SeverityLevel } from "@microsoft/applicationinsights-common";

const infoLog = (message) => {
    let obj = {
        message: message,
        severityLevel: SeverityLevel.Information
    };

    let insights = getAppInsights();
    if (insights) {
        insights.trackTrace(obj);
    }
}

const warningLog = (message) => {

    let obj = {
        message: message,
        severityLevel: SeverityLevel.Warning
    };

    let insights = getAppInsights();
    if (insights) {
        insights.trackTrace(obj);
    }
}

const errorLog = (error) => {
    let obj = {
        message: error,
        severityLevel: SeverityLevel.Error
    };

    let insights = getAppInsights();
    if (insights) {
        insights.trackException(obj);
    }
}

const eventLog = (name, properties) => {

    let obj = {
        name,
        properties
    };

    let insights = getAppInsights();
    if (insights) {
        insights.trackEvent(obj);
    }
}

export {
    infoLog,
    warningLog,
    errorLog,
    eventLog
};