import React from "react";
import { withTranslation, Trans } from "react-i18next";
import logo from "./../../../assets/img/logo.png";
import Loading from "./../../widgets/Loading";
import { app, dialog, pages } from "@microsoft/teams-js";
import Api from "../../../Api";
import { infoLog, errorLog } from "../../telemetry/LogServiceInterface";

class ConfigureTabStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      loading: true,
      context: {},
      frameContext: "",
    };
  }
  componentDidMount() {
    var Context = {};

    app
      .getContext()
      .then((_context) => {
        Context.locale = _context.app.locale;
        Context.theme = _context.app.theme;
        Context.userObjectId = _context.user.id;
        Context.email = _context.user.userPrincipalName;

        this.state.frameContext = _context.page.frameContext;
        this.setState({ context: Context }, this.auth);
        infoLog("Success Loading configure context");
      })
      .catch((error) => {
        errorLog("get context in feedback error: " + JSON.stringify(error));
        console.error(error);
      });
  }

  render() {
    return this.state.loading ? (
      <Loading displayStyle="initial" />
    ) : (
      <div className="div-center" id="page-confirm">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Voicero</h5>
            <p className="card-text">{this.state.message}</p>
          </div>
          <div>
            <img src={logo} className="logo-app" alt="Voicero" width="35" />
          </div>
        </div>
      </div>
    );
  }

  async auth() {
    infoLog("");

    let authorizeRequestOptions = {
      successCallback: (result) => {
        this.authorizeSuccess(result);
      },
      failureCallback: (error) => {
        this.authorizeFailure(error);
      },
    };

    await Api.authorizeTabStatic(
      this.state.context.email,
      authorizeRequestOptions
    );
  }

  authorizeSuccess = async (result) => {
    infoLog("Authorization Voicero Success");

    this.setState({
      message: this.props.t("messageInfoSuccess"),
      loading: false,
    });

    window.location.href =
      window.location.origin + "/tab-static/home-tab-static";
  };

  authorizeFailure(error) {
    var msg = "";
    
    if (error.status == 404) {
      this.setState({
        message: this.props.t("messageInfoError"),
        loading: false,
      });
      msg = "NotFound";
    } else {
      this.setState({
        message: (
          <Trans i18nKey="messageInfoForbidden">
            Prefix{" "}
            <a href="https://voicero.net/" target="_blank">
              link
            </a>{" "}
            suffix
          </Trans>
        ),
        loading: false,
      });
      msg = "Forbidden";
    }
    setTimeout(() => {
      if (this.state.frameContext.toLocaleLowerCase() == "task")
        dialog.submit(msg);
      else pages.config.setValidityState(false);
    }, 1500);
  }
}

export default withTranslation()(ConfigureTabStatic);
