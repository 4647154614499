import React from "react";
import logo from './../../assets/img/logo.png';

class Loading extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="div-center text-center" id="page-loading" style={{ "display": this.props.displayStyle }}>
                <div style={{ "marginBottom": "10px" }}>
                    <img src={logo} alt="Voicero" width="35" />
                </div>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        );
    }
}

export default Loading;
