import React from "react";

const BASE_API = process.env.REACT_APP_VOICERO_API;

var Api;
(function (Api) {
  Api.leaveBot = async (threadId, resultCallback) => {
    await fetch(`${BASE_API}/Bot/${threadId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: "",
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.pauseParticipant = async (threadId, userId, resultCallback) => {
    await fetch(`${BASE_API}/Participant/Pause/${userId}/${threadId}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.resumeParticipant = async (threadId, userId, resultCallback) => {
    await fetch(`${BASE_API}/Participant/Resume/${userId}/${threadId}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.getParticipant = async (threadId, userId, resultCallback) => {
    await fetch(`${BASE_API}/Participant/` + userId + `/` + threadId, {
      method: "get",
      headers: { "Content-Type": "Application/Json" },
    })
      .then((response) => response.json())
      .then((data) => resultCallback.successCallback(data))
      .catch((reason) => resultCallback.failureCallback(reason));
  };

  Api.getConfigUser = async (userId, resultCallback) => {
    await fetch(`${BASE_API}/User/Settings/` + userId, {
      method: "get",
      headers: { "Content-Type": "Application/Json" },
    })
      .then((response) => response.json())
      .then((data) => resultCallback.successCallback(data))
      .catch((reason) => resultCallback.failureCallback(reason));
  };

  Api.saveSettings = async (
    userId,
    languageIn,
    languageOut,
    voice,
    resultCallback
  ) => {
    console.log(voice);
    let params = JSON.stringify({
      UserId: userId,
      InputLanguage: languageIn,
      OutputLanguage: languageOut,
      OutputVoiceGender: voice,
    });
    console.log(params);
    await fetch(`${BASE_API}/User/Settings`, {
      method: "post",
      headers: { "Content-Type": "Application/Json" },
      body: params,
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.sendTranslation = async (
    threadId,
    participant,
    languageIn,
    languageOut,
    voice,
    resultCallback
  ) => {
    var params = JSON.stringify({
      UserId: participant,
      SourceLanguage: languageIn,
      TargetLanguage: languageOut,
      OutputVoiceGender: voice,
      ThreadId: threadId,
    });
    await fetch(`${BASE_API}/Participant/Configure`, {
      method: "post",
      headers: { "Content-Type": "Application/Json" },
      body: params,
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.listLanguage = async (locale, resultCallback) => {
    console.log(locale);
    await fetch(`${BASE_API}/Language?locale=${locale}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => resultCallback.successCallback(data))
      .catch((reason) => resultCallback.failureCallback(reason));
  };

  Api.authorize = async (
    meetingId,
    threaId,
    userId,
    tenantId,
    accessToken,
    email,
    resultCallback
  ) => {
    var params = JSON.stringify({
      ChatId: threaId,
      userObjectId: userId,
      TId: tenantId,
      AccessToken: accessToken,
      Email: email,
    });
    fetch(`${BASE_API}/Bot`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: params,
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.authorizeTabStatic = async (emailUser, resultCallback) => {
    let params = JSON.stringify({
      Email: emailUser,
    });

    fetch(`${BASE_API}/Bot/AuthorizeTabStatic`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: params,
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.sendFeedback = async (
    userId,
    email,
    threadId,
    questions,
    comments,
    resultCallback
  ) => {
    await fetch(`${BASE_API}/Feedback`, {
      method: "post",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        userId: userId,
        email: email,
        threadId: threadId,
        questions: questions,
        comments: comments,
      }),
    })
      .then((result) => {
        if (result.ok) resultCallback.successCallback(result);
        else resultCallback.failureCallback(result);
      })
      .catch((reason) => {
        resultCallback.failureCallback(reason);
      });
  };

  Api.getWebPubSubConnect = async (userId, threadId, resultCallback) => {
    await fetch(`${BASE_API}/negotiate/${userId}/${threadId}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => resultCallback.successCallback(data))
      .catch((reason) => resultCallback.failureCallback(reason));
  };
})(Api || (Api = {}));

export default Api;
