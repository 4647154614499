import React from "react";
import { withTranslation, Trans } from "react-i18next";
import logo from './../../assets/img/logo.png';
import Loading from './../widgets/Loading';
import { app, dialog, pages, authentication } from "@microsoft/teams-js";
import Api from '../../Api';
import { infoLog, errorLog } from '../telemetry/LogServiceInterface';

class Configure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            loading: false,
            context: {},
            frameContext: '',
            ssoToken: "",
            graphAccessToken: "",
            error: false
        };
    }

    componentDidMount() {
        this.getMessage();
        // Get the user context from Teams and set it in the state
        var Context = {};
        app.getContext().then((context) => {
            Context.locale = context.app.locale;
            Context.theme = context.app.theme;
            Context.userObjectId = context.user.id;
            Context.userPrincipalName = context.user.userPrincipalName;
            Context.tid = context.user.tenant.id;
            Context.meetingId = context.meeting.id;
            Context.chatId = context.chat.id;

            this.state.frameContext = context.page.frameContext;

            localStorage.setItem('userPrincipalName', context.user.userPrincipalName);

            infoLog('Success Loading configure context');
        });

        this.setState({ context: Context });
    }

    render() {
        return (
            this.state.loading ?
                <Loading displayStyle="initial" />
                :
                <div className="div-center" id="page-confirm">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Voicero</h5>
                            <p className="card-text">
                                {this.state.message}
                            </p>
                        </div>
                        <div>
                            <img src={logo} className="logo-app" alt="Voicero" width="35" />
                        </div>
                    </div>
                </div>
        )
    }

    async getMessage() {
        this.setState({
            message: this.props.t('messageConfirm'),
            loading: false,
        });
        setTimeout(() => {
            this.setState({
                loading: true,
            });
            this.requestConsent();
        }, 1500);
    }

    requestConsent = () => {
        authentication.authenticate({
            url: window.location.origin + "/auth-start",
            width: 600,
            height: 535
        }).then((result) => {
            this.consentSuccess(result)
        }).catch((reason) => {
            this.consentFailure(reason)
        });
    }

    async consentSuccess(result) {
        infoLog('Authentication Microsoft Office Success');
        this.setState({
            graphAccessToken: result
        });
        let authorizeRequestOptions = {
            successCallback: (result) => { this.authorizeSuccess(result) },
            failureCallback: (error) => { this.authorizeFailure(error) }
        };
        await Api.authorize(this.state.context.meetingId, this.state.context.chatId, this.state.context.userObjectId, this.state.context.tid, result, this.state.context.userPrincipalName, authorizeRequestOptions)
    }

    consentFailure(reason) {
        errorLog('Authentication Microsoft Office Error: ' + JSON.stringify(reason));
        this.setState({
            message: this.props.t('messageInfoNotAuth'),
            loading: false,
        });
    }

    authorizeSuccess = async (result) => {
        infoLog('Authorization Voicero Success');

        this.setState({
            message: this.props.t('messageInfoSuccess'),
            loading: false,
        });
        if (this.state.frameContext.toLocaleLowerCase() == 'task')
            setTimeout(() => {
                dialog.submit("Success");
            }, 1500);
        else {
            pages.config.registerOnSaveHandler((saveEvent) => {
                pages.config.setConfig({
                    contentUrl: window.location.origin + "?email={loginHint}&tenant={tid}&meetingId={meetingId}&chatId={chatId}&userId={userObjectId}",
                    entityId: "Voicero",
                    suggestedDisplayName: "Voicero"
                }).then((result) => {
                    infoLog('Added Voicero icon in Call');
                    console.log(result);
                }).catch((reason) => {
                    errorLog('Try Add Voicero icon in Call: ' + JSON.stringify(reason));
                    console.error(reason);
                });
                saveEvent.notifySuccess();
            });
            pages.config.setValidityState(true);
        }
    }

    authorizeFailure(error) {
        var msg = '';
        if (error.status == 401) {
            this.setState({
                message: this.props.t('messageInfoUnauthorized'),
                loading: false,
            });
            msg = 'Unauthorized';
        } else if (error.status == 403) {
            this.setState({
                message: (<Trans i18nKey="messageInfoForbidden">Prefix <a href="https://voicero.net/" target="_blank">link</a> suffix</Trans>),
                loading: false,
            });
            msg = 'Forbidden';
        } else if (error.status == 404) {
            this.setState({
                message: this.props.t('messageInfoError'),
                loading: false,
            });
            msg = 'NotFound';
        } else {
            this.setState({
                message: this.props.t('messageErrorAuth'),
                loading: false,
            });
            msg = 'Error';
            errorLog('Occurr error in authorize user : ' + JSON.stringify(error));
        }
        setTimeout(() => {
            if(this.state.frameContext.toLocaleLowerCase() == 'task')
                dialog.submit(msg);
            else
                pages.config.setValidityState(false);
        }, 1500);
    }
}

export default withTranslation()(Configure);
