import React from "react";
import { withTranslation } from "react-i18next";

class VoicesTalking extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pauseConnect: false,
            audioCtx: {},
            wsAudio: {},
            wsAudioUri: '',
        };
    }

    render() {
        // t -> Translations by languages resources
        const { t } = this.props;
        return (
            <div className="row voices-talking">
                <div className="col-sm-12">
                    {
                        this.props.Talking.length > 0 ?
                            <>
                                {
                                    this.props.Talking.length > 1 ?
                                        <p className="label-white text-center" style={{ "fontSize": "16px" }}> {t('voiceroListining')} {this.props.Talking.length} {t('participants')} </p>
                                        :
                                        <p className="label-white text-center" style={{ "fontSize": "16px" }}> {t('voiceroListining')} {this.props.Talking[0] === 'you' ? t('you') : this.props.Talking[0]}</p>
                                }
                            </>
                            : null
                    }
                    {
                        this.props.Translation.length > 0 ?
                            <>
                                {
                                    this.props.Translation.length > 1 ?
                                        <p className="label-white text-center" style={{ "fontSize": "16px" }}> {t('voiceroTranslating')} {this.props.Translation.length} {t('participants')} </p>
                                        :
                                        <p className="label-white text-center" style={{ "fontSize": "16px" }}> {t('voiceroTranslating')} {this.props.Translation[0] === 'you' ? t('you') : this.props.Translation[0]}</p>
                                }
                            </>
                            : null
                    }
                </div>
            </div>
        );
    }
}

export default withTranslation()(VoicesTalking);
