import React from "react";
import crypto from 'crypto-js';

import { app, Context, authentication } from "@microsoft/teams-js";

class AuthStart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {

        app.initialize()
            .then((response) => {
                let client_id = process.env.REACT_APP_AZURE_APP_REGISTRATION_ID; //Client ID of the Azure AD app registration ( may be from different tenant for multitenant apps)
                let graph_scopes = process.env.REACT_APP_GRAPH_SCOPES;

                //Form a query for the Azure implicit grant authorization flow
                //https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow      
                let queryParams = {
                    client_id: `${client_id}`,
                    response_type: "id_token token",
                    response_mode: "fragment",
                    scope: `https://graph.microsoft.com/${graph_scopes}`,
                    redirect_uri: window.location.origin + "/auth-end",
                    nonce: crypto.SHA256(new Date().getTime()).toString(crypto.enc.Base64),
                    state: crypto.SHA256(new Date().getTime()).toString(crypto.enc.Base64),
                    // login_hint pre-fills the username/email address field of the sign in page for the user,
                    // if you know their username ahead of time.
                    login_hint: localStorage.userPrincipalName
                };
                console.log("queryParams: ", queryParams);
                let url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?`;
                queryParams = new URLSearchParams(queryParams).toString();
                let authorizeEndpoint = url + queryParams;

                //Redirect to the Azure authorization endpoint. When that flow completes, the user will be directed to auth-end
                //Go to ClosePopup.js
                window.location = authorizeEndpoint;
            })
            .catch((error) => {
                authentication.notifyFailure("App initialize failed");
            });
    }
    render() {
        return (
            <>
            </>
        )
    }

}

export default AuthStart;
