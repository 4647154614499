export const TRANSLATIONS_ES = {
    // Home page
    'welcome': 'Bienvenido a Voicero',
    'cancel': 'Cancelar',
    'configure': 'Configurar',
    'confirm': 'Confirmar',
    'error': 'Error',
    'female': 'Feminina',
    'help': 'Ayuda',
    'iAmActive': 'Activo',
    'inactive': 'Inactivo',
    'leave': 'Abandonar',
    'male': 'Masculina',
    'messageParticipant': 'Espere a que el organizador inicie el traductor',
    'messageIAmActive': 'Haga Clic en el Ícono para Pausarme',
    'messageInactive': 'Complete la Configuración',
    'messageInactiveTabStatic': 'Complete o cambie su configuración para guardar',
    'messageLeave': '¿Está seguro de que desea desactivar el bot de chat de la reunión?',
    'messageReadyToStart': 'Haga Clic en el Ícono para Activar',
    'messageReadyToSave': 'Haga Clic en el Ícono para Guardar',
    'messageWelcome': 'Hola Voicero está en la reunión.Puedo realizar la traducción entre 18 idiomas en la reunión de Teams.Cuando quieras puedes silenciarme/ reactivarme o pedirme que abandone la reunión si ya no me necesitas.',
    'messageSuccessAuth': 'Autenticado con éxito!',
    'messageUnauthorizedAuth': 'No estás autorizado.Consulta tu suscripción.',
    'messageErrorAuth': '¡Algo salió mal! Inténtalo de nuevo.',
    'mute': 'Silenciar',
    'organize': 'Organizador:',
    'play': 'Tocar',
    'readyToStart': 'Empezar',
    'readyToSave': 'Guardar',
    'reload': 'Recargar',
    'save': 'Ahorrar',
    'saving': 'Ahorro',
    'selectInput': 'Seleccionar entrada',
    'selectInputDesc': 'Idioma nativo',
    'selectOutput': 'Seleccionar salida',
    'selectOutputDesc': 'Idioma para la traducción',
    'selectVoice': 'Seleccionar voz',
    'selectVoiceDesc': 'Voz deseada',
    'starting': 'Comenzando',
    'step': 'Paso',
    'stop': 'Deténgase',
    'titleHelp': 'Ayuda Aplicación Voicero',
    'titleLeave': 'Salir de la aplicación Voicero',
    'titleMute': 'Silenciar Voicero',
    'titlePause': 'Silenciar traducción',
    'titlePlay': 'Activar traducción',
    'titleSave': 'Guardar ajustes',
    'titleUnmute': 'Activar Voicero',
    'unmute': 'Activar',
    'voiceroSettings': 'Ajustes de Voicero',
    'voiceroListining': 'Voicero está traduciendo',
    'voiceroTranslating': 'Voicero está escuchando',
    'participants': 'participantes',
    'you': '',
    // Feedback page
    'messageSendError': 'Feedback no enviados, espere y vuelva a intentarlo.',
    'messageSendSuccess': 'Feedback enviado con éxito.',
    'question1': '¿Sobre la calidad de la traducción?',
    'question2': '¿Recomienda Voicero?',
    'question3': 'Déjanos tu opinión.',
    'send': 'Enviar',
    'sending': 'Enviando',
    'success': 'Éxito',
    'titleSend': 'Enviar feedback',
    'voiceroFeedback': 'Voicero Feedback',
    // Configure page
    'messageButton': 'Acceso',
    'messageConfirm': 'Iniciando autenticación',
    'messageConfigSuccess': 'Configuración guardada correctamente',
    'messageConfigFailure': 'Configuración No enviada, espere y vuelva a intentarlo',
    'messageInfoNotAuth': 'Realice una autenticación para insertar el bot en la llamada',
    'messageInfoError': 'No está autorizado para usar la aplicación Voicero, comuníquese con el organizador de la reunión.',
    'messageInfoSuccess': 'Está autorizado a usar la aplicación Voicero, ¡estamos iniciando el traductor!',
    'messageInfoUnauthorized': '¡Voicero ya está en esta llamada!',
    'messageInfoForbidden': 'Lo sentimos, no pudimos encontrar su suscripción a Voicero. Compruebe si su correo electrónico está borrado o consulte nuestras opciones de suscripción en nuestra <1>página</1>. ¡Gracias!',
    //tab page
    'textTitile': 'Activa tu potencial',
    'textFirstParagrafh': 'Voicero traduce la voz en tiempo real, lo que facilita expresar sus mejores ideas durante las reuniones de Microsoft Teams.',
    'textSecondParagrafh': 'Entra y configura Voicero con la reunión en curso.',
    'textThirthParagrafh': 'Deberá hacer clic en la pestaña para configurar',
    'messageUsingVoicero': 'Uso de Voicero',
    'messageTutorials': 'Tutoriales',
    'messageButton2': 'Ver Site'
};
