import React from "react";
import { withTranslation } from "react-i18next";

class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timerCount: 29,
            intervalTimer: 0,
            remainingPathColor: 'green',
            circleDasharray: '283 283',
            FULL_DASH_ARRAY: 283,
            TIME_LIMIT: 30
        };
    }

    async componentDidMount() {
        this.setTimerFunction(this.props.statusTimer);
    }

    async componentDidUpdate(prevProps) {
        if (this.props.statusTimer !== prevProps.statusTimer) {
            this.setTimerFunction(this.props.statusTimer);
        }
    }

    stopInterval = () => {
        clearInterval(this.state.intervalTimer);
        this.props.setTimerState('stopped');
    }

    zeroInterval = () => {
        this.setState({
            remainingPathColor: 'gray'
        }, () => {
            clearInterval(this.state.intervalTimer);
            this.props.setTimerState('zero');
        });
        
    }

    startInterval = () => {
        return setInterval(() => {
            this.setState(prevState => {
                let timeLeft;
                if (prevState.timerCount === undefined) {
                    timeLeft = 30 - 1;
                } else {
                    timeLeft = prevState.timerCount - 1;
                }
                this.setRemainingPathColor(timeLeft);
                this.setCircleDasharray(timeLeft);

                if (prevState.timerCount > 0) {
                    return {
                        timerCount: timeLeft
                    };
                } else {
                    console.log(prevState.timerCount);
                    this.zeroInterval();
                    return {
                        timerCount: 0
                    };
                }
            })
        }, 1000);
    }

    setTimerFunction = (statusTimer) => {
        switch (statusTimer) {
            case 'error':
                this.setState({
                    intervalTimer: 0
                });
                break;
            case 'started':
                this.setState({
                    intervalTimer: this.startInterval(),
                    circleDasharray: '283 283',
                    timerCount: 29
                }, () => this.props.setTimerState('running'));
                break;
            case 'stopping':
                this.stopInterval()
                break;
            case 'disabled':
                clearInterval(this.state.intervalTimer);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            this.props.statusTimer !== 'stopped' ?
                <div className="base-timer">
                    <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g className="base-timer__circle">
                            <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
                            <path
                                id="base-timer-path-remaining"
                                strokeDasharray={this.state.circleDasharray}
                                className={"base-timer__path-remaining " + this.state.remainingPathColor}
                                d="
                                    M 50, 50
                                    m -45, 0
                                    a 45,45 0 1,0 90,0
                                    a 45,45 0 1,0 -90,0
                                "
                            ></path>
                        </g>
                    </svg>
                    <span id="base-timer-label" className="base-timer__label">{
                        this.state.timerCount
                    }</span>
                </div>
            :
                <></>
        );
    }


    setRemainingPathColor = (timeLeft) => {
        if (timeLeft <= 5 && timeLeft > 0) {
            this.setState({
                remainingPathColor: 'red'
            });
        } else if (timeLeft <= 10 && timeLeft > 5) {
            this.setState({
                remainingPathColor: 'orange'
            });

        } else if (timeLeft <= 29 && timeLeft > 10) {
            this.setState({
                remainingPathColor: 'green'
            });
        } else {
            this.setState({
                remainingPathColor: 'gray'
            });
        }
    }

    calculateTimeFraction = (timeLeft) => {
        const rawTimeFraction = timeLeft / this.state.TIME_LIMIT;
        return rawTimeFraction - (1 / this.state.TIME_LIMIT) * (1 - rawTimeFraction);
    }

    setCircleDasharray = (timeLeft) => {
        var calculator = this.calculateTimeFraction(timeLeft) * this.state.FULL_DASH_ARRAY;
        this.setState({
            circleDasharray: calculator.toFixed(0) + ' 283'
        });
    }
}

export default withTranslation()(Timer);
