import React from "react";
import { withTranslation } from "react-i18next";
import logo from './../../assets/img/logo.png';

class Leave extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { t } = this.props;
        return (
            <div className="div-center top-46" id="page-close">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title"> Voicero </h5>
                        <p className="card-text">
                            {t('messageLeave')}
                        </p>
                        <p className="mt-3" style={{ "minHeight": "31px", "marginBottom": "0" }} >
                            <a onClick={() => { this.props.restartAppMethod(); }} className="btn btn-sm btn-primary-white">
                                <i className="fas fa-check"></i> {t('confirm')}
                            </a>&nbsp;
                            <a onClick={() => { this.props.hideLeaveMethod(); }} className="btn btn-sm btn-secondary-white">
                                <i className="fas fa-times"></i> {t('cancel')}
                            </a>
                        </p>
                    </div>
                    <div>
                        <img src={logo} className="logo-app" alt="Voicero" width="35" />
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Leave);
