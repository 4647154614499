import React from "react";

class Alert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="row" id="page-alerts"
                style={{ "position": "absolute", "marginTop": "15px", "left": "50%", "transform": "translate(-50%, -50%)" }}>
                <div className="col-sm-12 text-center">
                    <div>
                        <span className="text-muted" id="msg-alert">
                            <label>{this.props.message}</label>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Alert;
