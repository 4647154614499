import React from "react";
import logo from './../../assets/img/logo.png';
import { withTranslation, Trans } from "react-i18next";
import { dialog, authentication } from "@microsoft/teams-js";
import { infoLog, errorLog} from '../telemetry/LogServiceInterface';

class StandBy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ''
        };
    }

    render() {
        const { t } = this.props;

        return (
            <div className="div-center" style={{"width": "100%"}}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="mt-3 text-center">
                            <img src={logo} alt="Voicero" width="75" />
                        </div>
                    </div>
                    {
                        !this.props.organize ?
                            <div className="row" id="area-participant">
                                <div className="col-sm-6 width-100">
                                    <span className="btn btn-sm btn-primary mt-2 text-center" title={ t('organize') }>
                                        {t('messageParticipant') }
                                    </span>
                                </div>
                            </div>
                            :
                            <div className="row width-100" id="area-organize">
                                <div className="col-sm-12 width-100">
                                    <p className="mt-2 text-center">
                                        <a onClick={this.requestConsent} className="btn btn-sm btn-primary" title={t('configure')}>
                                            <i className="fas fa-reload"></i>
                                            {t('configure')}

                                        </a>
                                    </p>
                                </div>
                                <div className="col-sm-12 width-100">
                                    <p className="mt-2 text-center">
                                        { this.state.message }
                                    </p>
                                </div>
                            </div>
                                
                    }                    
                </div>
            </div>
        );
    }

    requestConsent = () => {
        if (window.addEventListener) {
            window.addEventListener("message", this.receiveMessageByWindow, false);
        } else {
            window.attachEvent("onmessage", this.receiveMessageByWindow);
        }

        authentication.authenticate({
            url: window.location.origin + "/auth-start",
            width: 600,
            height: 535
        }).then((result) => {
            this.authorizeSuccess(result);
        }).catch((reason) => {
            this.authorizeFailure(reason)
        });
    }

    receiveMessageByWindow = (msg) => {
        if (msg.data.args) {
            msg.data.args.forEach((data) => {
                switch (data) {
                    case 'success':
                        this.authorizeSuccess(data);
                        break;
                    case 'Unauthorized': 
                    case 'Forbidden': 
                    case 'NotFound': 
                    case 'Error':
                        this.authorizeFailure(data);
                        break;
                    default:
                        break;
                }
            });
        }
    }

    authorizeSuccess = (result) => {
        infoLog('Authorization Voicero Success');
        this.setMessage(this.props.t('messageSuccessAuth'));
    }

    authorizeFailure(error) {
        var returnMessagge = error.message ? error.message : error;
        if (returnMessagge === 'Unauthorized') {
            this.setState({
                message: this.props.t('messageInfoUnauthorized'),
                loading: false,
            });
        } else if (returnMessagge === 'Forbidden') {
            this.setState({
                message: (<Trans i18nKey="messageInfoForbidden">Prefix <a href="https://voicero.net/" target="_blank">link</a> suffix</Trans>),
                loading: false,
            });
        } else if (returnMessagge === 'NotFound') {
            this.setState({
                message: this.props.t('messageInfoError'),
                loading: false,
            });
        } else {
            this.setState({
                message: this.props.t('messageErrorAuth'),
                loading: false,
            });
            errorLog('Occurr error in authorize user : ' + JSON.stringify(error));
        }
    }


    showTaskConfigure = () => {
        let dialogInfo = {
            title: null,
            size: {
                height: null,
                width: null,
            },
            url: null,
            fallbackUrl: null
        };

        dialogInfo.url = window.location.origin + '/configure';
        dialogInfo.title = "Voicero Configure";
        dialogInfo.size.height = 510;
        dialogInfo.size.width = 430;
        let submitHandler = (result) => {
            if (result.result === "Unauthorized") {//Bot j� esta na call
                window.location.reload();
            } else if (result.result === "Forbidden") {//Subscription invalida
                this.setMessage(this.props.t('messageUnauthorizedAuth'));
            } else if (result.result === "NotFound") {// User not Organize
                this.setMessage(this.props.t('messageInfoError'));
            } else { // Problema no bot, fechou o dialog, meet nao encontrada
                this.setMessage(this.props.t('messageErrorAuth'));
            }
        };
        dialog.open(dialogInfo, submitHandler);
    }

    setMessage = (msg) => {
        this.setState({
            message: msg
        }, console.log(this.state));
    }
}

export default withTranslation()(StandBy);
