import React from "react";

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="footer">
                <div className="container pl-pr-0 text-center">
                    <div className="row">
                        <div className="col-sm-12 col-hr"><hr /></div>
                        {
                            this.props.leaveShow ?
                                <div className="col-sm-12 col-hr" id="btn-leave-temp">
                                    <p>
                                        <a onClick={() => { this.props.showLeaveMethod(); }} className="a-hover" title="TitleLeave"><i className="fas fa-sign-in-alt info-icon"></i></a>
                                    </p>
                                </div>
                                :
                                null
                        }
                        <div className="col-sm-12">
                            <font className="info-app">
                                <a href="http://voicero.net" target="_blank" title="TitleHelp"><i className="fas fa-question-circle"></i> </a>
                                <label style={{ "fontSize": "10px" }} >{ this.props.appName }</label>
                            </font>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
