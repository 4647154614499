import React from "react";

import Api from '../../Api';
import WebPubSub from '../../WebPubSub';

import Loading from './../widgets/Loading';
import Form from './../widgets/Form';
import Preplay from './../widgets/Preplay';
import Play from './../widgets/Play';
import Pause from './../widgets/Pause';
import Loadingcfg from './../widgets/Loadingcfg';
import Alert from './../widgets/Alert';
import Leave from './../widgets/Leave';
import Footer from './../widgets/Footer';
import StandBy from './../widgets/StandBy';
import VoicesTalking from './../widgets/VoicesTalking';
import Timer from './../widgets/Timer';

import { app, Context } from "@microsoft/teams-js";
import config from '../../config.json';

import { infoLog, errorLog } from '../telemetry/LogServiceInterface';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            message: "",
            loading: true,
            setupShow: false,
            leaveShow: false,
            languages: [],
            tabStatic: false,
            formData: {
                input: '',
                output: '',
                voice: '',
            },
            formSelectDisabled: {
                input: false,
                output: false,
                voice: false,
            },
            organize: {
                id: '',
                email: '',
                isOrganize: false,
            },
            context: {
                locale: '',
                theme: '',
                userObjectId: '',
                userPrincipalName: '',
                tid: '',
                meetingId: '',
                chatId: '',
            },
            bot: {
                callId: '',
                isMuted: false,
                instanceId: ''
            },
            Talking: [],
            Translation: [],
            cmpntTimer: "disabled",
            cmpntFormShow: true,
            cmpntPrePlayShow: true,
            cmpntPlayShow: false,
            cmpntPauseShow: false,
            cmpntLoadingCfgShow: false,
            cmpntAlertShow: true,
            cmpntLeaveShow: false,
            cmpntVoicesCfgShow: false
        };


    }

    async componentDidMount() {
        var Context = {};

        app.getContext().then((_context) => {
            Context.locale = _context.app.locale;
            Context.theme = _context.app.theme;
            Context.userObjectId = _context.user.id;
            Context.userPrincipalName = _context.user.userPrincipalName;
            Context.tid = _context.user.tenant.id;
            Context.meetingId = _context.meeting.id;
            Context.chatId = _context.chat.id;
            localStorage.setItem('userPrincipalName', _context.user.userPrincipalName);

            this.setState({ context: Context });
        }).catch((error) => {
            console.error(error)
        });
    }

    render() {
        return (
            <>
                {
                    this.state.loading ?
                        <Loading displayStyle="initial" />
                        :
                        <>
                            {
                                this.state.setupShow ?
                                    <div className="div-center top-35" id="page-setup-temp">
                                        {/* Form Area */}
                                        {
                                            this.state.cmpntFormShow ? <Form setFormDataMethod={this.setFormData} formData={this.state.formData} listLanguage={this.listLanguage} languages={this.state.languages} formSelect={this.state.formSelectDisabled} /> : null
                                        }
                                        {/* Action Area */}
                                        {
                                            this.state.cmpntPrePlayShow ?
                                                <Preplay />
                                                :
                                                this.state.cmpntPlayShow ?
                                                    <Play playBot={this.playBot} tabStatic={this.state.tabStatic} />
                                                    :
                                                    this.state.cmpntPauseShow ?
                                                        <Pause pauseBot={this.pauseBot} bot={this.state.bot} context={this.state.context} />
                                                        :
                                                        null
                                        }
                                        {/* Show timer */}
                                        {
                                            this.state.cmpntTimer != 'disabled' ?
                                                <Timer statusTimer={this.state.cmpntTimer} setTimerState={this.setTimerState} />
                                                : null
                                        }
                                        {/* Show translation */}
                                        {
                                            this.state.cmpntVoicesCfgShow ?
                                                <VoicesTalking Talking={this.state.Talking} Translation={this.state.Translation} />
                                                : null
                                        }
                                        {/* Loading Config Area */}
                                        {
                                            this.state.cmpntLoadingCfgShow ? <Loadingcfg /> : null
                                        }
                                        {/* Alert Area */}
                                        {
                                            this.state.cmpntAlertShow ? <Alert message={this.state.message} /> : null
                                        }
                                    </div>
                                    :
                                    <StandBy organize={this.state.organize} />
                            }
                            {
                                this.state.cmpntLeaveShow ? <Leave restartAppMethod={this.leaveBot} hideLeaveMethod={this.hideLeave} /> : null
                            }
                            <Footer showLeaveMethod={this.showLeave} leaveShow={this.state.leaveShow} appName={config.version.name} />
                        </>
                }
                <WebPubSub timer={this.state.cmpntTimer} setTimer={this.setTimerState} addTalking={this.addTalking} removeTalking={this.removeTalking} addTranslation={this.addTranslation} removeTranslation={this.removeTranslation} userInfo={this.getUserInfo} showLoading={this.showLoading} playSuccess={this.playBotSuccess} pauseSuccess={this.pauseBotSuccess} translationSuccess={this.sendTranslationSuccess} getPageState={this.getPageState} />
            </>

        );
    }

    setTimerState = (state) => {
        this.setState({
            cmpntTimer: state
        }, () => console.log(this.state.cmpntTimer));
    }

    addTalking = (displayName) => {
        let talking = this.state.Talking;
        const index = talking.indexOf(displayName);
        if (index < 0) {
            talking.push(displayName);
        }
        this.setState({
            Talking: talking
        }, () => console.log(this.state.Talking));
    }

    removeTalking = (displayName) => {
        let talking = this.state.Talking;
        const index = talking.indexOf(displayName);
        if (index > -1) {
            talking.splice(index);
        }
        this.setState({
            Talking: talking
        });
    }

    addTranslation = (displayName) => {
        let translation = this.state.Translation;
        const index = translation.indexOf(displayName);
        if (index < 0) {
            translation.push(displayName);
        }
        this.setState({
            Translation: translation
        }, () => console.log(this.state.Translation));
    }

    removeTranslation = (displayName) => {
        let translation = this.state.Translation;
        const index = translation.indexOf(displayName);
        if (index > -1) {
            translation.splice(index);
        }
        this.setState({
            Translation: translation
        });
    }

    getPageState = async () => {
        if (this.state.loading) {
            return "LOADING";
        } else if (this.state.setupShow) {
            return "FORM";
        } else {
            return "STANDBY";
        }
    }

    getUserInfo = async () => {
        let getParticipantRequestOptions = {
            successCallback: (result) => { this.getParticipantSuccess(result) },
            failureCallback: (error) => { this.getParticipantFailure(error) }
        };

        await Api.getParticipant(this.state.context.chatId, this.state.context.userObjectId, getParticipantRequestOptions);
    }

    getParticipantSuccess = async (result) => {
        infoLog('Success Get User Info');
        if (result.meet) {
            this.setOrganize({
                id: result.meet.organizeId,
                email: result.meet.organizeEmail,
                isOrganize: (this.state.context.userObjectId === result.meet.organizeId)
            }, () => {
                this.renderForm(result)
            });
        } else {
            this.renderForm(result);
        }

    }

    renderForm(result) {
        if (!result.isActiveBot) {
            if (this.state.organize.isOrganize)
                this.showConfigureOrganize()
            else
                this.showConfigureParticipant()
            return;
        }
        if (result.translation != null && result.translation.sourceLanguage != null && result.translation.targetLanguage != null && result.translation.outputVoice != null && result.translation.outputVoiceGender != null) {
            infoLog('User set languages for translated' + JSON.stringify(result.translation));
            var form = {
                input: result.translation.sourceLanguage,
                output: result.translation.targetLanguage,
                voice: result.translation.outputVoiceGender.toLowerCase()
            };
            var select = {
                input: result.translation.active,
                output: result.translation.active,
                voice: result.translation.active,
            };

            this.setState({
                formData: form,
                formSelectDisabled: select
            }, () => {
                this.showForm(() => { this.setButtonTranslation(result.translation.active) });
            });

        } else {
            this.showForm(this.setButtonDefaultTranslation);
        }
    }

    getParticipantFailure(error) {
        console.error(error);
        errorLog('Error Get User Info' + JSON.stringify(error));
        if (error.responseJSON != null) {
            this.setOrganize({
                id: error.responseJSON.meet.organizeId,
                email: error.responseJSON.meet.organizeEmail,
                isOrganize: (this.state.context.userObjectId === error.responseJSON.meet.organizeId)
            }, () => { console.log(this.state) });
        }
    }

    leaveBot = async () => {
        let leaveBotRequestOptions = {
            successCallback: (result) => { this.leaveBotSuccess(result) },
            failureCallback: (error) => { this.leaveBotFailure(error) }
        };

        await Api.leaveBot(this.state.context.chatId, leaveBotRequestOptions);
    }

    leaveBotSuccess = async (result) => {
        infoLog('Leave bot success');
        window.location = window.location.origin + '/feedback';
    }

    leaveBotFailure(error) {
        errorLog('Leave bot error: ' + JSON.stringify(error));
        window.location.reload();
    }

    showLeave = () => {
        this.setState({
            ...this.state,
            setupShow: false,
            cmpntLeaveShow: true,
        }, () => console.log(this.state));
    }

    showForm = (callback) => {
        this.setState({
            loading: false,
            setupShow: true,
            cmpntVoicesCfgShow: true,
            leaveShow: this.state.organize.isOrganize,
        }, callback);
    }

    showLoading = () => {
        this.setState({
            loading: true,
            setupShow: false,
            cmpntVoicesCfgShow: false,
            leaveShow: false
        });
    }

    showConfigureOrganize = () => {
        this.setState({
            ...this.state,
            loading: false,
            setupShow: false,
            cmpntVoicesCfgShow: false,
        }, () => console.log(this.state));
    }

    showConfigureParticipant = () => {
        this.setState({
            ...this.state,
            loading: false,
            setupShow: false,
            cmpntVoicesCfgShow: false,
        }, () => console.log(this.state));
    }

    hideLeave = () => {
        this.setState({
            ...this.state,
            setupShow: true,
            cmpntVoicesCfgShow: true,
            cmpntLeaveShow: false,
        }, () => console.log(this.state));
    }

    setFormData = async (data) => {
        this.setState({
            ...this.state,
            formData: data
        }, async () => {
            if (this.state.formData.input !== '' && this.state.formData.output !== '' && this.state.formData.voice !== '') {

                this.setStartingLoading();
                let sendTranslationRequestOptions = {
                    successCallback: (result) => { infoLog('Send Translation sucess') },
                    failureCallback: (error) => { this.sendTranslationFailure(error) }
                };

                await Api.sendTranslation(this.state.context.chatId, this.state.context.userObjectId, this.state.formData.input, this.state.formData.output, this.state.formData.voice, sendTranslationRequestOptions)
            } else {
                this.setButtonDefaultTranslation();
            }
        });
    }

    setButtonDefaultTranslation = () => {
        this.setState({
            ...this.state,
            cmpntPrePlayShow: true,
            cmpntPlayShow: false,
            cmpntPauseShow: false
        });
    }

    setButtonTranslation = (state) => {
        this.setState({
            ...this.state,
            cmpntPrePlayShow: false,
            cmpntPlayShow: !state,
            cmpntPauseShow: state
        });
    }

    setStartingLoading = async () => {
        var select = {
            input: true,
            output: true,
            voice: true,
        };
        this.setState({
            ...this.state,
            cmpntLoadingCfgShow: true,
            formSelectDisabled: select
        }, () => {
            this.setButtonTranslation(false);
        });

    }

    sendTranslationSuccess = async (result) => {
        var select = {
            input: false,
            output: false,
            voice: false,
        };
        this.setState({
            ...this.state,
            cmpntLoadingCfgShow: false,
            formSelectDisabled: select,

        }, () => {

        });
    }

    sendTranslationFailure(error) {
        console.error(error);
        errorLog('Send transaltion error: ' + JSON.stringify(error));
    }

    setOrganize = (data, callback) => {
        this.setState({
            organize: data
        }, callback);
    }

    listLanguage = async () => {
        let listLanguageRequestOptions = {
            successCallback: (result) => { this.listLanguageSuccess(result) },
            failureCallback: (error) => { this.listLanguageFailure(error) }
        };

        await Api.listLanguage('pt', listLanguageRequestOptions);

        return this.state.languages;
    }

    listLanguageSuccess = async (result) => {
        infoLog('Get Language List Success');
        if (result.length > 0) {
            this.state.languages = result;
        }
    }

    listLanguageFailure(error) {
        errorLog('Get Language List Error');
        console.error(error);
    }

    playBot = async () => {
        this.setStartingLoading();
        let playBotRequestOptions = {
            successCallback: (result) => { infoLog('play bot sucess') },
            failureCallback: (error) => { this.playBotFailure(error) }
        };

        await Api.resumeParticipant(this.state.context.chatId, this.state.context.userObjectId, playBotRequestOptions);
    }

    playBotSuccess = async (result) => {
        var select = {
            input: true,
            output: true,
            voice: true,
        };
        this.setState({
            ...this.state,
            cmpntLoadingCfgShow: false,
            formSelectDisabled: select
        }, () => {
            this.setButtonTranslation(true);
        });
    }

    playBotFailure(error) {
        errorLog('Error in play bot: ' + JSON.stringify(error));
        console.error(error);
    }

    pauseBot = async () => {
        this.setStartingLoading();
        let pauseBotRequestOptions = {
            successCallback: (result) => { infoLog('pause bot sucess') },
            failureCallback: (error) => { this.pauseBotFailure(error) }
        };

        await Api.pauseParticipant(this.state.context.chatId, this.state.context.userObjectId, pauseBotRequestOptions);
    }

    pauseBotSuccess = async (result) => {
        var select = {
            input: false,
            output: false,
            voice: false,
        };
        this.setState({
            ...this.state,
            cmpntLoadingCfgShow: false,
            formSelectDisabled: select,
            cmpntTimer: 'disabled'
        }, () => {
            this.setButtonTranslation(false);
        });
    }

    pauseBotFailure(error) {
        this.setState({
            ...this.state,
            cmpntTimer: 'error'
        });
        errorLog('Error in pause bot: ' + JSON.stringify(error));
        console.error(error);
    }

}

export default Home;
