import React from "react";
import { app } from "@microsoft/teams-js";
import Api from './Api';
import { infoLog, errorLog, eventLog } from './components/telemetry/LogServiceInterface';

var ws;

class WebPubSub extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            webPubSubUri: '',
            context: {
                userObjectId : '',
                chatId : '',
            }
        };
    }

    async componentDidMount() {
        var Context = {};

        app.getContext().then((_context) => {
            Context.userObjectId = _context.user.id;
            Context.chatId = _context.chat.id;

            this.setState({ context: Context }, this.getWebPubSubUrl);
        }).catch((error) => {
            errorLog('get context in webpub sub error: ' + JSON.stringify(error));
            console.error(error)
        });

    }

    render() {
        return ('');
    }

    getWebPubSubUrl = async () => {
        let getWebPubSubUrlRequestOptions = {
            successCallback: (result) => { this.getWebPubSubUrlSuccess(result) }, //The result variable is the SSO token.
            failureCallback: (error) => { this.getWebPubSubUrlFailure(error) }
        };

        await Api.getWebPubSubConnect(this.state.context.userObjectId, this.state.context.chatId, getWebPubSubUrlRequestOptions);
    }

    getWebPubSubUrlSuccess = async (result) => {
        infoLog("Get webPubSub Success");
        this.setState({
            webPubSubUri: result.uri
        }, await this.connectWebSocket)
    }

    getWebPubSubUrlFailure(error) {
        errorLog("Get WebPubSub error: " + JSON.stringify(error))
        console.log(error);
    }

    connectWebSocket = async () => {
        ws = new WebSocket(this.state.webPubSubUri, 'json.webpubsub.azure.v1');

        ws.onopen = () => {
            console.log('OPEN');
            ws.send(JSON.stringify({
                type: 'joinGroup',
                group: this.state.context.chatId,
                userId: this.state.context.userObjectId
            }));

            this.props.userInfo();
        };

        ws.onmessage = event => {
            let message = JSON.parse(event.data);
            var command = "";

            if (message.type === 'message' && message.group === this.state.context.chatId) {
                command = message.data.Command ? message.data.Command : message.data.command
                var mensagem = message.data.Message ? message.data.Message : message.data.message
                var contentMessagem = {};

                switch (command) {
                    case "CREATEMEETING":
                        eventLog("Create meeting success", message);
                        if (this.props.getPageState === "STANDBY")
                            this.props.showLoading();
                        break;

                    case "BOTJOINED":
                        if (mensagem === "NoParticipant") {
                            eventLog("Bot joined success with zero participants", message);
                        } else {
                            eventLog("Bot joined success with participants", message);
                            if (this.props.getPageState !== "FORM")
                                this.props.userInfo();
                        }
                        break;

                    case "CALLTERMINATED":
                        eventLog("Call terminated success", message);
                        if (this.props.getPageState === "FORM")
                            window.location.href = window.location.origin + "/feedback";
                        break;

                    case "PARTICIPANTSPEECHSTATECHANGE":
                        eventLog("Participant Translation State Changed", message);
                        contentMessagem = JSON.parse(mensagem);

                        if (contentMessagem.State === "started" && this.state.context.userObjectId === contentMessagem.UserId) {
                            this.props.setTimer("started");
                            this.props.addTalking("you")
                        } else if (contentMessagem.State === "stopped" && this.state.context.userObjectId === contentMessagem.UserId) {
                            this.props.setTimer("stopping");
                            this.props.removeTalking("you")
                        } else if (contentMessagem.State === "started") {
                            this.props.addTalking(contentMessagem.DisplayName)
                        } else {
                            this.props.removeTalking(contentMessagem.DisplayName)
                        }
                        break;

                    case "TRANSLATIONREPRODUCTIONCHANGED":
                        eventLog("Speech State Changed", message);
                        contentMessagem = JSON.parse(mensagem);

                        if (contentMessagem.State === "started" && this.state.context.userObjectId === contentMessagem.UserId) {
                            this.props.addTranslation("you")
                        } else if (contentMessagem.State === "stopped" && this.state.context.userObjectId === contentMessagem.UserId) {
                            this.props.removeTranslation("you")
                        } else if (contentMessagem.State === "started") {
                            this.props.addTranslation(contentMessagem.DisplayName)
                        } else {
                            this.props.removeTranslation(contentMessagem.DisplayName)
                        }
                        break;
                    default:
                        this.props.userInfo();
                        break;
                }

            } else if (message.type === 'message') {
                command = message.data.Command ? message.data.Command : message.data.command

                switch (command) {
                    case "PAUSEDTRANSLATION":
                        eventLog("Paused tranlation success", message);
                        this.props.pauseSuccess({});
                        break;
                    case "RESUMEDTRANSLATION":
                        eventLog("Resumed tranlation success", message);
                        this.props.playSuccess({});

                        break;
                    case "CONFIGURETRANSLATION":
                        eventLog("Configuration tranlation success", message);
                        this.props.translationSuccess({})
                        break;
                    default:
                        break;
                }
            }
        }
    }
}

export default WebPubSub;