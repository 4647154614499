import React from "react";
import { withTranslation } from "react-i18next";
import stepsgif from './../../assets/img/stepsgif.gif';
import { app, pages } from "@microsoft/teams-js";

class Tab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            containerStyle: 'container-dark'
        };
    }

    componentDidMount() {
        var theme = '';
        app.getContext().then((_context) => {
            theme = _context.app.theme;
            theme = 'container-' + (theme === 'dark' ? 'dark' : 'light');
            this.setState({
                containerStyle: theme
            });

        }).catch((error) => {
            console.error(error)
        });
        setTimeout(this.initiateApp, 500);
    }

    render() {
        const { t } = this.props;
        return (
            <main role="main" className={ this.state.containerStyle }>
                <div className="row">
                    <div className="col-sm-12 col-hr page-tab">
                        <p><b>{ t('textTitile') }</b></p>
                        <p>{ t('textFirstParagrafh') }</p>
                        <p>{ t('textSecondParagrafh') }</p>
                        <p>​{ t('textThirthParagrafh') }</p>
                    </div>
                    <div className="col-sm-12 col-hr text-center">
                        <img src={stepsgif} alt="" width="80%" />
                    </div>
                </div>
                <footer className={this.state.containerStyle}>
                    <div className="container pl-pr-0 text-center">
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="info-app">
                                    {t('messageUsingVoicero')} &nbsp;&nbsp;
                                    <a href="https://voicero.net/Tutorials" target="_blank" title={ t('messageTutorials') }>
                                        { t('messageTutorials') }
                                    </a>
                                    |
                                    <a href="https://voicero.net/Home" target="_blank">
                                        { t('messageButton2') }
                                    </a>

                                </p>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>
        )
    }

    initiateApp = () => {
        pages.config.registerOnSaveHandler((saveEvent) => {
            pages.config.setConfig({
                contentUrl: window.location.origin + "?email={loginHint}&tenant={tid}&meetingId={meetingId}&chatId={chatId}&userId={userObjectId}",
                entityId: "Voicero",
                suggestedDisplayName: "Voicero"
            }).then((result) => {
                console.log(result);
            }).catch((reason) => {
                console.error(reason);
            });
            saveEvent.notifySuccess();
        });
        pages.config.setValidityState(true);
    }
}

export default withTranslation()(Tab);
