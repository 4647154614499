import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';

class TelemetryProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false
        };
    }
    

    componentDidMount() {
        const { history } = this.props;
        const { initialized } = this.state;

        const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
        const AppInsightsConnectionString = this.props.connectionString; // PUT YOUR KEY HERE
        //const AppInsightsInstrumentationKey = '7a6f73e6-3efa-40d1-a08f-8e5190a203c4'; // PUT YOUR KEY HERE
        //const AppInsightsConnectionString = "InstrumentationKey=7a6f73e6-3efa-40d1-a08f-8e5190a203c4;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/"; // PUT YOUR KEY HERE
        //const AppInsightsInstrumentationKey = '28627ea0-bafc-4843-9c03-c76b2b37d6ad'; // PUT YOUR KEY HERE
        //const AppInsightsConnectionString = "InstrumentationKey=28627ea0-bafc-4843-9c03-c76b2b37d6ad;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/"; // PUT YOUR KEY HERE
        //const AppInsightsInstrumentationKey = 'b45edbf5-9086-4b02-968a-d33f4d8f2dd2'; // PUT YOUR KEY HERE
        //const AppInsightsConnectionString = "InstrumentationKey=b45edbf5-9086-4b02-968a-d33f4d8f2dd2;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/"; // PUT YOUR KEY HERE
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(AppInsightsConnectionString) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, AppInsightsConnectionString, history);
            this.setState({ initialized: true });
        } else {
            console.error("Not initialte app insigths");
        }

        this.props.after();
    }

    render() {
        const { children } = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withAITracking(ai.reactPlugin, TelemetryProvider);