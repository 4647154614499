import React from "react";
import { withTranslation } from "react-i18next";

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputLanguages: [],
            outputLanguages: [],
            form: {
                input: '',
                output: '',
                voice: '',
            }
        };
    }

    async componentDidMount() {
        this.updateLocalState(this.props.formData);
        this.setListLanguages();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <form className="mt-3">
                    <div className="form-group" id="select-lang-input">
                        <label htmlFor="selectInput"> <b>{t('step')} 1</b> &middot; {t('selectInputDesc')} </label>
                        <select onChange={this.handleSelectInput} className="form-control" id="selectInput" name="selectInput" value={this.props.formData.input} disabled={ this.props.formSelect.input }>
                            <option value=""> {t('selectInput')} </option>
                            {
                                this.state.inputLanguages.map((input) => (
                                    <option key={input.abbreviation} value={input.abbreviation}>{input.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group" id="select-lang-output">
                        <label htmlFor="selectOutput"> <b>{t('step')} 2</b> &middot; {t('selectOutputDesc')} </label>
                        <select onChange={this.handleSelectOutput} className="form-control" id="selectOutput" name="selectOutput" value={this.props.formData.output} disabled={this.props.formSelect.output}>
                            <option value=""> {t('selectOutput')} </option>
                            {
                                this.state.outputLanguages.map((output) => (
                                    <option key={output.abbreviation} value={output.abbreviation}>{output.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="form-group" id="select-voice">
                        <label htmlFor="selectVoice"> <b>{t('step')} 3</b> &middot; {t('selectVoiceDesc')} </label>
                        <select onChange={this.handleSelectVoice} className="form-control" id="selectVoice" name="selectVoice" value={this.props.formData.voice} disabled={this.props.formSelect.voice}>
                            <option value=""> {t('selectVoice')} </option>
                            <option value="male"> {t('male')} </option>
                            <option value="female"> {t('female')} </option>
                        </select>
                    </div>
                </form>
            </>
        );
    }

    handleSelectInput = (event) => {
        var valueInput = event.target.value;
        this.setState({
            form: {
                ...this.state.form,
                input: valueInput,
                output: '',
                voice: ''
            },
        }, () => {
            this.setState({
                outputLanguages: this.state.inputLanguages.filter(function (arr) {
                    return arr.abbreviation !== valueInput || arr.abbreviation === '';
                })
            });
            this.props.setFormDataMethod(this.state.form);
        });
    }

    handleSelectOutput = (event) => {
        var valueOutput = event.target.value;

        this.setState({
            form: {
                ...this.state.form,
                output: valueOutput,
                voice : ''
            },
        }, () => {
            this.props.setFormDataMethod(this.state.form);
        });
    }

    handleSelectVoice = (event) => {
        this.setState({
            form: {
                ...this.state.form,
                voice: event.target.value,
            },
        }, () => {
            this.props.setFormDataMethod(this.state.form);
        });
    }

    updateLocalState = (state) => {
        this.setState({
            form: state
        }, () => {});
    }

    setListLanguages = async () => {
        var list = await this.props.listLanguage();

        if (this.state.form.output !== '') {
            var inputValue = this.state.form.input;
            var listOutput = list.filter(function (arr) {
                return arr.abbreviation !== inputValue || arr.abbreviation === '';
            });
            this.setState({
                inputLanguages: list,
                outputLanguages: listOutput
            }, () => { });
        } else {
            this.setState({
                inputLanguages: list
            }, () => { } );
        }
    }
}

export default withTranslation()(Form);
