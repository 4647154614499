import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {

    const initialize = (instrumentationKey, connectionString, browserHistory) => {

        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }
        if (!connectionString) {
            throw new Error('Connection String not provided in ./src/telemetry-provider.jsx')
        }
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided in ./src/telemetry-provider.jsx')
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                connectionString: connectionString,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });

        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((envelope) => {
            envelope.tags = envelope.tags || [];
            envelope.tags.push({ "ai.cloud.role": "VoiceroReactTab" });
        });
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;