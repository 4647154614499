export const TRANSLATIONS_PT = {
  // Home page
  'welcome': 'Bem-vindo ao Voicero',
  'cancel': 'Cancelar',
  'configure': 'Configurar',
  'confirm': 'Confirmar',
  'error': 'Erro',
  'female': 'Feminina',
  'help': 'Ajuda',
  'iAmActive': 'Ativo',
  'inactive': 'Inativo',
  'leave': 'Sair',
  'male': 'Masculina',
  'messageParticipant': 'Aguarde o organizador iniciar o tradutor',
  'messageIAmActive': 'Clique no Ícone para me Pausar',
  'messageInactive': 'Por Favor, Complete a Configuração',
  'messageInactiveTabStatic': 'Por Favor, Complete ou altere sua configuração para salvar',
  'messageLeave': 'Tem certeza de que deseja desativar o bot de bate- papo da reunião?',
  'messageReadyToStart': 'Clique no Ícone para Ativar',
  'messageReadyToSave': 'Clique no Ícone para Salvar',
  'messageWelcome': 'Olá o Voicero está na reunião.Posso realizar a tradução entre 18 idiomas na reunião do Teams.Sempre que quiser, você pode me silenciar / reativar ou me pedir para sair da reunião se você não precisar mais de mim.',
  'messageSuccessAuth': 'Autenticado com sucesso!',
  'messageUnauthorizedAuth': 'Você não está autorizado.Verifique sua subscrição!',
  'messageErrorAuth': 'Alguma coisa deu errado! Por favor, tente novamente.',
  'mute': 'Mutar',
  'organize': 'Organizador:',
  'play': 'Ativar',
  'readyToStart': 'Começar',
  'readyToSave': 'Salvar',
  'reload': 'Atualizar',
  'save': 'Salvar',
  'saving': 'Salvando',
  'selectInput': 'Selecione a Entrada',
  'selectInputDesc': 'Idioma nativo',
  'selectOutput': 'Selecione a Saída',
  'selectOutputDesc': 'Idioma para tradução',
  'selectVoice': 'Selecione uma Voz',
  'selectVoiceDesc': 'Voz desejada',
  'starting': 'Iniciando',
  'step': 'Passo',
  'stop': 'Parar',
  'titleHelp': 'Ajuda Aplicativo Voicero',
  'titleLeave': 'Sair do aplicativo Voicero',
  'titleMute': 'Silenciar Voicero',
  'titlePause': 'Silenciar tradução',
  'titlePlay': 'Ativar tradução',
  'titleSave': 'Salvar configurações',
  'titleUnmute': 'Desmutar Voicero',
  'unmute': 'Falar',
  'voiceroSettings': 'Voicero Definições',
  'voiceroListining': 'Voicero está escutando',
  'voiceroTranslating': 'Voicero está traduzindo',
  'participants': 'participantes',
  'you': 'você',
  // Feedback page
  'messageSendError': 'Feedback não enviado, aguarde e tente novamente.',
  'messageSendSuccess': 'Feedback enviado com sucesso.',
  'question1': 'Sobre a qualidade da tradução?',
  'question2': 'Você recomenda o Voicero?',
  'question3': 'Deixe-nos a sua opinião.',
  'send': 'Enviar',
  'sending': 'Enviando',
  'success': 'Sucesso',
  'titleSend': 'Enviar feedback',
  'voiceroFeedback': 'Voicero Feedback',
  // Configure page
  'messageButton': 'Entrar',
  'messageConfirm': 'Iniciando autenticação',
  'messageConfigSuccess': 'Configuração salva com sucesso',
  'messageConfigFailure': 'Configuração não enviada, aguarde e tente novamente',
  'messageInfoNotAuth': 'Realize a autenticação para inserir o bot na chamada',
  'messageInfoError': 'Somente o organizador desta reunião pode inserir o Voicero nesta chamada',
  'messageInfoSuccess': 'Você está autorizado a usar o Voicero App, estamos iniciando o tradutor!',
  'messageInfoUnauthorized': 'O Voicero já está nesta chamada!',
  'messageInfoForbidden': 'Desculpe, não encontramos sua assinatura do Voicero. Verifique se seu e-mail está liberado ou confira nossas opções de assinatura na nossa <1>página</1>. Obrigado!',
  //tab page
  'textTitile': 'Ative seu potencial',
  'textFirstParagrafh': 'O Voicero traduz a voz em tempo real, facilitando a expressão de suas melhores ideias durante as reuniões do Microsoft Teams.',
  'textSecondParagrafh': 'Entre e configure o Voicero com a reunião em andamento.',
  'textThirthParagrafh': 'Você precisará clicar na guia para configurar',
  'messageUsingVoicero': 'Utilizando o Voicero',
  'messageTutorials': 'Tutoriais',
  'messageButton2': 'Ver Site'

};
